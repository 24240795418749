/**
 * トップページに関する処理
 */

export default class Home {
  constructor(_parm) {
    this.pageParent = document.querySelector(_parm.parent) || false;
    if (!this.pageParent) return;
  }

  sample() {
    // console.log('This is Home Page.');
  }

  init() {
    if (!this.pageParent) return;
    this.sample();
  }
}
