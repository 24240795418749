// Funtion
import adjustViewport from './function/adjustViewport';

// Module
import SmoothScroll from './module/SmoothScroll';

// Page
import Home from './page/Home';

const init = () => {
  (() => {
    const smoothScroll = new SmoothScroll({
      targets: 'a[href^="#"]',
      fixHeader: '#js-header',
    });
    smoothScroll.init();
  })();

  (() => {
    const home = new Home({
      parent: '#js-home-page',
    });
    home.init();
  })();

  // splide実行
  (() => {
    // sectionkv
    var splide_seckv = new Splide('.p-kv__splide', {
      type: 'loop',
      gap: '60px',
      arrows: false,
      pagination: false,
      drag: false,
      perPage: 6,
      autoScroll: {
        speed: 0.8,
        pauseOnHover: false,
        pauseOnFocus: false,
      },
      breakpoints: {
        768: {
          perPage: 3,
          gap: '40px',
        },
      },
    });

    // section05
    var splide_sec05 = new Splide('.p-section05__splide', {
      type: 'loop',
      padding: 10,
      gap: '50px',
      arrows: false,
      classes: {
        pagination: 'splide__pagination p-section05__pagination',
        page: 'splide__pagination__page p-section05__page',
      },
      breakpoints: {
        768: {
          destroy: true,
          // gap: '25px',
        },
      },
    });
    // section06
    var splide_sec06 = new Splide('.p-section06__splide', {
      padding: 10,
      // gap: '50px',
      gap: '0',
      arrows: false,
      classes: {
        pagination: 'splide__pagination p-section06__pagination',
        page: 'splide__pagination__page p-section06__page',
      },
      breakpoints: {
        768: {
          gap: '25px',
        },
      },
    });
    // section07
    var splide_sec07 = new Splide('.p-section07__splide', {
      padding: 10,
      gap: '20px',
      arrows: false,
      pagination: false,
      breakpoints: {
        768: {
          destroy: true,
        },
      },
    });
    // section08
    var splide_sec08 = new Splide('.p-section08__splide', {
      padding: 10,
      gap: '20px',
      arrows: false,
      classes: {
        pagination: 'splide__pagination p-section08__pagination',
        page: 'splide__pagination__page p-section08__page',
      },
      breakpoints: {
        768: {
          destroy: true,
        },
      },
    });
    // section10
    // var splide_sec10 = new Splide('.p-section10__splide', {
    //   type: 'loop',
    //   padding: 10,
    //   arrows: false,
    //   pagination: false,
    //   autoScroll: {
    //     speed: 0.7, // スクロール速度
    //   },
    // });
    splide_seckv.mount(window.splide.Extensions);
    splide_sec05.mount();
    splide_sec06.mount();
    splide_sec07.mount();
    splide_sec08.mount();
    // splide_sec10.mount(window.splide.Extensions);
  })();
};

window.addEventListener('DOMContentLoaded', () => {
  adjustViewport();
  init();
});
